import axios from 'axios';


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getTemplate(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/template', { params: _params }).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getTemplateGroup(_, id) {
            return new Promise((resolve, reject) => {
                axios.get('/template-carrer-interest-survei/getTemplateGroup/' + id).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        getTemplateSubGroup(_, id) {
            return new Promise((resolve, reject) => {
                axios.get('/template-carrer-interest-survei/getTemplateSubGroup/' + id).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },
    },
    getters: {

    }

}