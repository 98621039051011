import axios from 'axios';


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getQuestion(_, id) {
            return new Promise((resolve, reject) => {
                axios.get('/test-question/getPertanyaan/' + id).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addQuestion(_, data) {
            return new Promise((resolve, reject) => {
                axios.post('/test-question/create', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateQuestion( id, data ) {
            return new Promise((resolve, reject) => {
                axios.post('/test-question/update/' + id, data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                });
            });
        },
        getTemplateSub(_, id) {
            return new Promise((resolve, reject) => {
                axios.get('/test-template-sub-group/' + id).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },
        getTemplateSub(_, id) {
            return new Promise((resolve, reject) => {
                axios.get('/test-template-sub-group/' + id).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },
        addQuestionMbti(_, data) {
            return new Promise((resolve, reject) => {
                axios.post('/test-question/createMbti', data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },
        updateQuestionMbti(_, data) {
            return new Promise((resolve, reject) => {
                axios.put('/test-question/updateMbti' , data).then(resp => {
                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },
    },
    getters: {

    }

}