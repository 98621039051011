import Vue from 'vue'
import Vuex from 'vuex'
import auth from './module/auth'
import user from './module/user'
import category from './module/category'
import product from './module/product'
import user_register from './module/user_register'
import transaction from './module/transaction'
import result from './module/result'
import dashboard from './module/dashboard'
import company from './module/company'

// carrer interest group
import carrer_group from './module/carrer_group'
import carrer_question from './module/carrer_question'

// carrer interest group
import rmib_group from './module/rmib_group'
import rmib_question from './module/rmib_question'

//master test
import template from './module/template'
import test_group from './module/test_group'
import test_bundle from './module/test_bundle'
import history_payment from './module/history_payment'
import history_result from './module/history_result'
import test_question from './module/test_question'
import event from './module/event'
import registran from './module/registran'

Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false
}

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state, [variable, value]) {
    state[variable] = value
  }
}

export default new Vuex.Store({
  state,
  modules: {
    auth,
    user,
    category,
    product,
    user_register,
    transaction,
    result,
    history_payment,
    history_result,
    dashboard,
    company,
    carrer_group,
    carrer_question,
    rmib_group,
    rmib_question,
    test_group,
    template,
    test_bundle,
    test_question,
    event,
    registran
  },
  mutations
})