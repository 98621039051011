 import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views - menu
const Dashboard = () => import('@/views/menu/dashboard')
const MasterUser = () => import('@/views/menu/master-user')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const CategoryExportPDF = () => import('@/views/menu/components/category-pdf')
const ProductExportPDF = () => import('@/views/menu/components/product-pdf')
const UserExportPDF = () => import('@/views/menu/components/user-pdf')
const Product = () => import('@/views/menu/product')
const UserRegister = () => import('@/views/menu/user-register')
const Transaction = () => import('@/views/menu/transaction')
const TransactionExportPDF = () => import('@/views/menu/components/transaction-pdf')
const Result = () => import('@/views/menu/result')
const ResultExportPDF = () => import('@/views/menu/components/result-pdf')
const HistoryPaymentExportPDF = () => import('@/views/menu/components/history-payment-pdf')
const HistoryResultExportPDF = () => import('@/views/menu/components/history-result-pdf')
const Dash = () => import('@/views/Dashboard')
const Company = () => import('@/views/menu/company')
const CompanyDetail = () => import('@/views/menu/company-detail')
const HistoryPayment = () => import('@/views/menu/history-payment')
const HistoryResult = () => import('@/views/menu/history-result')

const CarrerGroup = () => import('@/views/menu/carrer-group')
const CarrerQuestion = () => import('@/views/menu/carrer-question')

const RmibGroup = () => import('@/views/menu/rmib-group')
const RmibQuestion = () => import('@/views/menu/rmib-question')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')

//Test route
const TestGroup = () => import('@/views/menu/test-group')
const TestBundle = () => import('@/views/menu/test-bundle')
const TemplateCarrerInterestSurvei = () => import('@/views/menu/template-carrer-interest-survei')
const TemplateUrutanSatuSampaiDuabelas = () => import('@/views/menu/template-urutan-satu-sampai-duabelas')
const TemplateVak = () => import('@/views/menu/template-vak')
const TemplateMbti = () => import('@/views/menu/template-mbti')
const Event = () => import('@/views/menu/event')
const Category = () => import('@/views/menu/category')
const Registran = () => import('@/views/menu/registran')

Vue.use(Router)

export default new Router({
  mode: 'hash',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/registran',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'dash',
          name: 'Dash',
          component: Dash
        },
        {
          path: 'master-user',
          redirect: '/master-user/user',
          name: 'Master User',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'user',
              name: 'User data',
              component: MasterUser
            },
          ]
        },
        {
          path: 'category-pdf',
          name: 'CategoryExportPDF',
          component: CategoryExportPDF
        },
        {
          path: 'product-pdf',
          name: 'ProductExportPDF',
          component: ProductExportPDF
        },
        {
          path: 'user-pdf',
          name: 'UserExportPDF',
          component: UserExportPDF
        },
        {
          path: 'transaction-pdf',
          name: 'TransactionExportPDF',
          component: TransactionExportPDF
        },
        {
          path: 'result-pdf',
          name: 'ResultExportPDF',
          component: ResultExportPDF
        },
        {
          path: 'history-payment-pdf',
          name: 'HistoryPaymentExportPDF',
          component: HistoryPaymentExportPDF
        },
        {
          path: 'history-result-pdf',
          name: 'HistoryResultExportPDF',
          component: HistoryResultExportPDF
        },
        {
          path: 'result',
          name: 'Result',
          component: Result
        },
        {
          path: 'transaction',
          name: 'Transaction',
          component: Transaction
        },
        {
          path: 'user-register',
          name: 'User Register',
          component: UserRegister
        },
        {
          path: 'category',
          name: 'Category',
          component: Category
        },
        {
          path: 'event',
          name: 'Event',
          component: Event
        },
        {
          path: 'registran',
          name: 'Registran',
          component: Registran
        },
        {
          path: 'product',
          name: 'Product',
          component: Product
        },
        {
          path: 'company',
          name: 'Profile Perusahaan',
          component: Company
        },
        {
          path: 'company-detail',
          name: 'Profile Perusahaan Detail',
          component: CompanyDetail
        },
        {
          path: 'user',
          name: 'User data',
          component: MasterUser
        },
        {
          path: 'carrer-group',
          name: 'Carrer Interest Group',
          component: CarrerGroup
        },
        {
          path: 'carrer-question',
          name: 'Carrer Interest Question',
          component: CarrerQuestion
        },
        {
          path: 'rmib-group',
          name: 'Test RMIB Group',
          component: RmibGroup
        },
        {
          path: 'rmib-question',
          name: 'Test RMIB Question',
          component: RmibQuestion
        },
        {
          path: 'test-group',
          name: 'Test Group',
          component: TestGroup
        },
        {
          path: 'test-bundle',
          name: 'Test Bundle',
          component: TestBundle
        },
        {
          path: 'test-template-vak',
          name: 'Visual, Auditory, Kinestetik',
          component: TemplateVak
        },
        {
          path: 'test-template-carrer-interest-survei',
          name: 'Carrer Interest Survei',
          component: TemplateCarrerInterestSurvei
        },
        {
          path: 'test-urutan-satu-sampai-duabelas',
          name: 'Urutan Satu Sampai Dua Belas',
          component: TemplateUrutanSatuSampaiDuabelas
        },
        {
          path: 'test-template-vak',
          name: 'Visual, Auditory, Kinestetik',
          component: TemplateVak
        },
        {
          path: 'test-template-mbti',
          name: 'MBTI',
          component: TemplateMbti
        },
        {
          path: 'users',
          meta: {
            label: 'Users'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Users',
              component: Users
            },
            {
              path: ':id',
              meta: {
                label: 'User Details'
              },
              name: 'User',
              component: User
            }
          ]
        },
        {
          path: 'history-payment',
          name: 'History Payment',
          component: HistoryPayment
        },
        {
          path: 'history-result',
          name: 'History Result',
          component: HistoryResult
        },
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
      ]
    }
  ]
}

